$custom-success: #257b69;
$custom-primary: #257b69;
$custom-text: #808080;
$custom-danger: #aa223c;
$custom-warning: #ff9700;
$custom-info: #1aafd0;
$custom-light: #c4c4c4;
$custom-dark: #1aafd0;
$custom-grey: #d7d7e0;
$custom-simple-text: #787b96;
$custom-concrete: #f2f2f2;
