.accrodian {
  &-btn {
    background-color: white;
    border: none;
    border-radius: 50%;
    box-shadow: 5px 5px 12px 5px rgba(0, 0, 0, 0.1);
    height: 30px;
    width: 30px;
  }
  &-icon {
    transition: 0.3s ease-in;
  }
  &-card {
    .card-header {
      h4 {
        color: $custom-primary;
      }
    }
  }
}
