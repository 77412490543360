section {
  .section- {
    &header {
      padding-bottom: 3px;
      border-bottom: 2px solid #f2f2f2 !important;
    }
    &badge {
      color: #fff;
      font-family: Latto-Bold;
      padding: 5px 30px !important;
      height: 34px;
      border-radius: 17px !important;
      background-color: $custom-primary;
      margin-top: -8px;
      margin-left: 40px;
    }
    &toggle {
      border: none;
      background: none;
      color: $custom-primary;
    }
  }
}
